// This is a list of all the event names that we are tracking in Segment
// PAGE VIEW EVENT NAMES
export const PAGE_ABOUT_HOME = 'About Home';
export const PAGE_PRESS_HOME = 'Press Home';
export const PAGE_PRESS_RELEASE = 'Press Release';
export const PAGE_PRESS_RELEASES_LIST = 'Press Releases List';
export const PAGE_BOOKING_CONFIRMATION = 'Booking Confirmation';
export const PAGE_BOOKING_SCHEDULE = 'Booking Schedule';
export const PAGE_CAMPAIGN_LANDING = 'Campaign Landing';
export const PAGE_HOME = 'Home';
export const PAGE_JOB_ADDRESS = 'Job Address';
export const PAGE_LOCATION_SHOW = 'Location Detail';
export const PAGE_NEAR_ME = 'Near Me';
export const PAGE_RECOMMENDATION = 'Recommendations';
export const PAGE_ROUTING_QUESTION = 'Routing Question';
export const PAGE_SCOPING_QUESTIONS = 'Scoping Questions';
export const PAGE_SCOPING_QUESTIONS_SUMMARY = 'Scoping Questions Summary';
export const PAGE_SERVICE_LOCATION = 'Service Location';
export const LOCATION_DETAIL = 'Location Detail';
export const PAGE_TASKER_PROFILE = 'Tasker Profile';
export const AUTO_INVITE_TASK_CARD = 'Auto-Invite Task Card';
export const AUTO_INVITE_MATCH_RESULT = 'Auto-Invite Match Result';

// EVENTS
export const ADDRESS_OUT_OF_COVERAGE = 'Address Out of Coverage';
export const EDIT_SCOPING_QUESTION =
  'Edit Scoping Question Response From Summary Button Clicked';
export const EDIT_TASK_CLICKED = 'Edit Task Button Clicked';
export const FAQ_EXPANDED = 'FAQ Expanded';
export const FIRST_RECOMMENDATION_VIEWED = 'First Recommendation Viewed';
export const JOB_ADDRESS_ENTERED = 'Job Address Entered';
export const JOB_ADDRESS_SUBMITTED = 'Job Address Submitted';
export const JOB_BOOKED = 'Job Booked';
export const JOB_DATETIME_SELECTED = 'Job DateTime Selected';
export const JOB_DETAILS_ADDED = 'Job Details Added';
export const JOB_OPTIONS_PICKED = 'Job Options Picked';
export const JOB_SUMMARY_SUBMITTED = 'Job Summary Submitted';
export const MARKETING_CATEGORY_FAMILY_CLICKED =
  'Marketing Category Family Clicked';
export const RECOMMENDATION_CATEGORY_PICKED = 'Recommendation Category Picked';
export const ROUTING_QUESTION_SUBMITTED = 'Routing Question Submitted';
export const SERVICE_LOCATION_CARD_CLICKED = 'Service Location Card Clicked';
export const SERVICE_PAGE_LINK_CLICKED = 'Service Page Link Clicked';
export const SCOPING_QUESTION_BACK_CLICKED =
  'Scoping Question Back Button Clicked';
export const SCOPING_QUESTION_RESPONSE_SUBMITTED =
  'Scoping Question Response Submitted';
// TRIGGER LOCATIONS
export const GET_HELP_TODAY_SECTION = 'Get Help Today Section';
export const MARKETING_CATEGORY_FAMILY_ICONS_SECTION =
  'Marketing Category Family Icons Section';
export const RECOMMENDATION_CATEGORY_SEARCH = 'Recommendation Category Search';
export const POPULAR_PROJECTS_SECTION = 'Popular Projects Section';
// JOB ADDRESS PAGE VARIANTS
export const PAGE_VARIANT = {
  booking_details: 'booking_details',
  scoping_questions: 'scoping_questions',
};
